<template>
	<div class="full-height flex-column justify-space-between">
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit"> {{ $language.subscribe.title_subscribe_plane_management }} <!-- 구독 플랜 관리 --></h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-80">
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_box_1">
						<div class="justify-space-between">
							<div class="img-box-100 mr-10 radius-20 overflow-hidden"><img :src="item.img_physl_path" @error="$bus.$emit('onErrorProfileImage', $event)" class="width-100"/></div>
							<div class="flex-1 flex-column justify-center size-px-14 text-left">
								<div class="font-weight-500">{{ item.subscrp_plan_name }}</div>
								<div class="mt-10">{{ item.subscrp_amount | makeComma }}</div>
								<div class="mt-10 color-gray">{{ item.subscrp_plan_state_name }}</div>
							</div>
						</div>
						<div
							class="mt-20 bg-gray-light pa-20 radius-20"
						>
							<h2 class="flex-row items-center"><v-icon>mdi-file-document</v-icon> 구독 플랜 설명</h2>
							<div
								:inner-html.prop="item.subscrp_plan_desctn | nl2br"
								class="size-px-14 mt-10 line-height-160"
							></div>
						</div>
						<div
							class="mt-20 bg-gray-light pa-20 radius-20"
						>
							<h2 class="flex-row items-center"><v-icon>mdi-file-document</v-icon> 결제수단</h2>
							<ul>
								<li
									v-for="(payment, p_index) in items_payment"
									:key="'payment_' + p_index"
									class="size-px-14 mt-10 line-height-160"
								>
									<div>{{ payment.payment_mthd_name }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="mt-auto pa-20 btn_area"
		>
			<button
				@click="toPlaneSetting"
				class="btn_l btn_fill_blue"
			>플랜 및 판매 수정</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SubscribePlaneDetail'
		, props: ['user']
		, data: function(){
			return {

				program: {
					name: this.$language.notice.add
					, title: this.$language.notice.add
					, not_footer: true
					, not_header: true
					, type: 'cartel_sub'

				}
				, item_cartel: {

				}
				, item:{
					subscrp_plan_number: 1
					, subscrp_plan_name: '루비'
					, subscrp_amount: 5000
					, subscrp_plan_desctn: '정보 우선 공개\n 미공개 사진 및 영상 공개\n 한달에 한번 오프라인 미팅'
					, subscrp_plan_state_code: ''
					, subscrp_plan_state_name: '판매중'
					, img_resolu_code: ''
					, img_physl_path: ''
					, subscrp_months_count: ''
					, payment_mthd_list: [
						{ subscrp_plan_payment_mthd_number: '', blockchain_div_div: '', payment_mthd_name: '신용카드', coin_token_code: ''}
						, { subscrp_plan_payment_mthd_number: '', blockchain_div_div: 'BC00100001', payment_mthd_name: 'ETH', coin_token_code: 'BC00200001'}
						, { subscrp_plan_payment_mthd_number: '', blockchain_div_div: 'BC00100001', payment_mthd_name: 'MAF', coin_token_code: 'BC00200002'}
					]
				}
				, subscrp_plan_commis_rate: {}
				, items_payment: []
			}
		}
		, methods: {
			getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, getPlane: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_subscribe_plane
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, subscrp_plan_number: this.$route.params.p_id
						}
						, type: true
					})

					if(result.success){
						this.item = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getPayment: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_subscribe_plane_base
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, subscrp_plan_number: this.$route.params.p_id
						}
						, type: true
					})

					if(result.success){
						this.subscrp_plan_commis_rate = result.data.subscrp_plan_commis_rate
						this.items_payment = result.data.payment_mthd_list
						// this.items = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toPlaneSetting: function(){
				this.$bus.$emit('to', { name: 'SubscribePlaneSetting', params: { idx: this.$route.params.idx, p_id: this.$route.params.p_id }})
			}
			, goBack: function(){
				this.$bus.$emit('goBack')
			}
		}
		, async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.getCartel()
			await this.getPlane()
			await this.getPayment()
		}
	}
</script>